@import url("https://fonts.googleapis.com/css2?family=Luckiest+Guy:wght@400;700;800&display=swap");

body {
  margin: 0;
  padding: 0;
  font-family: "Luckiest Guy", sans-serif;
  min-height: 100vh;
}

.root {
  display: flex;
  place-content: center flex-start;
  align-items: center;
  background-color: rgb(0, 0, 0);
  flex-flow: column nowrap;
  min-height: 100vh;
  gap: 10px;
  height: 100%;
  padding: 0px;
  position: relative;
  /* The image used */
  background-image: url("/images/bg.jpg");

  /* Full height */

  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.container {
  display: flex;
  flex: 0 0 auto;
  flex-flow: row nowrap;
  gap: 10px;
  margin: 30px;
  position: relative;
  width: auto;
  gap: 64px;
}

.header {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
  color: #fff;
}

.header-title {
  display: flex;
  gap: 12px;
}

.header-title h1 {
  width: 100%;
  font-weight: 700;
  font-size: 72px;
  letter-spacing: 2px;
  line-height: 1.2em;
  text-align: center;
  margin: 0;
}

.header-subtitle h2 {
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.4em;
  color: rgba(255, 255, 255, 0.75);
}

.prompt-container {
  place-content: flex-end center;
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  flex-flow: column nowrap;
  gap: 16px;
  height: min-content;
  padding: 0px;
  width: min-content;
}

.prompt-box {
  border-bottom-width: 1px;
  border-color: rgba(208, 32, 123, 0.1);
  border-left-width: 1px;
  border-right-width: 1px;
  border-style: solid;
  border-top-width: 1px;
  place-content: flex-start;
  align-items: flex-start;
  background-color: rgba(221, 221, 224, 0.305);
  border-radius: 16px;
  display: flex;
  flex: 0 0 auto;
  flex-flow: column nowrap;
  gap: 10px;
  overflow: hidden;
  padding: 20px;
  position: relative;
  width: 610px;
  max-width: 1200px;
  will-change: transform;
  overflow: auto;
  outline: none;
  resize: none;
  font-size: 16px;
  font-style: normal;
  letter-spacing: 0px;
  line-height: 1.6em;
  text-align: start;
  color: rgba(255, 255, 255, 0.97);
  text-decoration: none;
  text-transform: uppercase;
}

.prompt-box input {
  font-family: "Luckiest Guy", sans-serif;
}

.prompt-box:focus {
  border-color: rgba(255, 255, 255, 0.75);
}

.prompt-buttons {
  display: flex;
  place-content: center;
  align-items: center;
  flex: 0 0 auto;
  flex-flow: row nowrap;
  gap: 12px;
  height: min-content;
  overflow: hidden;
  padding: 0px;
  position: relative;
  width: 100%;
}

.generate-button {
  place-content: flex-start;
  align-items: flex-start;
  justify-content: center;
  text-align: center;
  display: flex;
  flex: 0 0 auto;
  flex-flow: column nowrap;
  gap: 10px;
  height: min-content;
  overflow: hidden;
  position: relative;
  text-decoration: none;
  /* width: min-content; */
  will-change: transform;
  cursor: pointer;
}

.generate {
  outline: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;
  transform: none;
}

.generate p {
  background-color: #3949b6;
  padding: 16px 24px;
  border-radius: 100px;
  color: rgb(255, 255, 255);
  margin: 0;
}

.span-text {
  overflow-wrap: break-word;
  max-width: 50%;
  margin-left: auto;
  margin-right: auto;
}

.loading {
  opacity: 0.7;
  cursor: default;
}

.badge-container {
  display: flex;
  bottom: 20px;
  flex: 0 0 auto;
  height: auto;
  left: 20px;
  position: fixed;
  width: auto;
  z-index: 1;
}

.badge-container a {
  border-bottom-width: 1px;
  border-color: rgba(255, 255, 255, 0.1);
  border-left-width: 1px;
  border-right-width: 1px;
  border-style: solid;
  border-top-width: 1px;
  background-color: rgb(0, 0, 0);
  border-radius: 54.3478% / 238.095%;
  filter: grayscale(1);
  transform: none;
  transform-origin: 50% 50% 0px;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: -0.5px;
  line-height: 1em;
  color: #fff;
  padding: 3px;
  text-decoration: none;
}

.badge-container a p {
  display: flex;
  align-items: center;
}

.badge {
  display: flex;
  align-items: center;
}

.badge img {
  width: 21px;
  height: 17px;
  image-rendering: pixelated;
  flex-shrink: 0;
  fill: black;
  color: black;
  transform: none;
  transform-origin: 50% 50% 0px;
  padding-right: 5px;
}

.badge p {
  margin: 0;
}

.grow {
  transition: all 0.2s ease-in-out;
}

.grow:hover {
  transform: scale(1.1);
}

.output-content {
  width: 512px;
  max-width: 90%;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  flex-shrink: 0;
  transform: none;
  margin-left: auto;
  margin-right: auto;
}

.output-content img {
  border-radius: 16px;
}

.output-content p {
  text-align: center;
  font-weight: bold;
  white-space: pre-line;
  color: white;
}

@media (max-width: 1200px) {
  .container {
    flex-direction: column;
    justify-content: center;
    max-width: 700px;
    width: 100%;
    /* padding: 50px; */
  }

  .prompt-container {
    max-width: 600px;
    width: 100%;
  }

  .output-content {
    width: 100%;
    height: 512px;
    align-items: center;
  }
}

@media (max-width: 809px) and (max-width: 600px) {
  .container {
    max-width: 550px;
    width: 100%;
  }

  .header-title h1 {
    font-size: 50px;
  }

  .header-subtitle h2 {
    text-align: center;
  }

  .prompt-buttons {
    justify-content: center;
  }

  .prompt-container {
    max-width: 400px;
    width: 100%;
  }

  .prompt-box {
    width: 100%;
  }
}

@media (max-width: 600px) {
  .container {
    width: 100% !important;
  }
}


/* Loading ring animation */
.loader {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  display: inline-block;
  border-top: 2px solid #fff;
  border-right: 2px solid transparent;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

